<template>
        <div>
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="line-box">
                                    <h5 class="text-secondary font-weight-light text-uppercase ls-100">Departments</h5>
                                    <h2 class="mb-0" v-if="response.departments.total">{{response.departments.total}}</h2>
                                    <h2 class="mb-0" v-else>-</h2>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="line-box">
                                    <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Doctors</h5>
                                    <h2 class="mb-0" v-if="response.users.total">{{response.users.total}}</h2>
                                    <h2 class="mb-0" v-else>-</h2>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="line-box">
                                    <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Inquiry</h5>
                                    <h2 class="mb-0" v-if="response.inquiry.total">{{response.inquiry.total}}</h2>
                                    <h2 class="mb-0" v-else>-</h2>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="line-box">
                                    <h5 class="text-secondary font-weight-light text-uppercase ls-100">Patients from Cities</h5>
                                    <h2 class="mb-0" v-if="response.cities.total">{{response.cities.total}}</h2>
                                    <h2 class="mb-0" v-else>-</h2>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="line-box">
                                    <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Medicine</h5>
                                    <h2 class="mb-0" v-if="response.medicines.total">{{response.medicines.total}}</h2>
                                    <h2 class="mb-0" v-else>-</h2>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="line-box">
                                    <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Prescription</h5>
                                    <h2 class="mb-0" v-if="response.prescription.total">{{response.prescription.total}}</h2>
                                    <h2 class="mb-0" v-else>-</h2>
                                </div>
                            </div>
                        </div>
                        <br class="d-none d-md-block" />
                        <hr class="d-none d-md-block" color="lightgrey"/>
                        <br class="d-none d-md-block"/>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="line-box">
                                    <h5 class="text-secondary font-weight-light text-uppercase ls-100">Total Room</h5>
                                    <h2 class="mb-0" v-if="response.room.total">{{response.room.total}}</h2>
                                    <h2 class="mb-0" v-else>-</h2>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Vecant</h5>
                                            <h2 class="mb-0" v-if="response.room.vecant">{{response.room.vecant}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Occupied</h5>
                                            <h2 class="mb-0" v-if="response.room.occupied">{{response.room.occupied}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Reserved</h5>
                                            <h2 class="mb-0" v-if="response.room.reserved">{{response.room.reserved}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Sp.Room</h5>
                                            <h2 class="mb-0" v-if="response.room.special_room">{{response.room.special_room}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Twin Room</h5>
                                            <h2 class="mb-0" v-if="response.room.twin_sharing_room">{{response.room.twin_sharing_room}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Gen Room</h5>
                                            <h2 class="mb-0" v-if="response.room.general_room">{{response.room.general_room}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100"><br/>Recovery Room</h5>
                                            <h2 class="mb-0" v-if="response.room.recovery_rrom">{{response.room.recovery_rrom}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100"><br/>ICU</h5>
                                            <h2 class="mb-0" v-if="response.room.icu">{{response.room.icu}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100"><br/>MICU</h5>
                                            <h2 class="mb-0" v-if="response.room.micu">{{response.room.micu}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100"><br/>SICU</h5>
                                            <h2 class="mb-0" v-if="response.room.sicu">{{response.room.sicu}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100"><br/>Ward</h5>
                                            <h2 class="mb-0" v-if="response.room.ward">{{response.room.ward}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-2">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br class="d-none d-md-block" />
                        <hr class="d-none d-md-block" color="lightgrey"/>
                        <br class="d-none d-md-block"/>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="line-box">
                                    <h5 class="text-secondary font-weight-light text-uppercase ls-100">Wards</h5>
                                    <h2 class="mb-0" v-if="response.ward.total">{{response.ward.total}}</h2>
                                    <h2 class="mb-0" v-else>-</h2>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">General</h5>
                                            <h2 class="mb-0" v-if="response.ward.general">{{response.ward.general}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Medical</h5>
                                            <h2 class="mb-0" v-if="response.ward.medical">{{response.ward.medical}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Surgical</h5>
                                            <h2 class="mb-0" v-if="response.ward.surgical">{{response.ward.surgical}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="line-box">
                                            <h5 class="text-secondary font-weight-light text-uppercase ls-100">Pediatric</h5>
                                            <h2 class="mb-0" v-if="response.ward.pediatric">{{response.ward.pediatric}}</h2>
                                            <h2 class="mb-0" v-else>-</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <div class="clearfix">
                                <div class="row">
                                    <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">OPDs({{opds_data.total}})</h4>
                                </div>
                            </div>
                            <canvas id="opds-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <div class="clearfix">
                                <div class="row">
                                    <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">OPD Renewal({{opds_renew.total}})</h4>
                                </div>
                            </div>
                            <canvas id="opds-renewal-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <div class="clearfix">
                                <div class="row">
                                    <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">Patients({{patients_data.total}})</h4>
                                </div>
                            </div>
                            <canvas id="patients-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <div class="clearfix">
                                <div class="row">
                                    <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">Appointments({{appointments_data.total}})</h4>
                                </div>
                            </div>
                            <canvas id="appointments-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <div class="clearfix">
                                <div class="row">
                                    <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">IPDs({{ipds_data.total}})</h4>
                                </div>
                            </div>
                            <canvas id="ipds-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <div class="clearfix">
                                <div class="row">
                                    <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">IPDs Treatments({{ipds_treatment_data.total}})</h4>
                                </div>
                            </div>
                            <canvas id="ipds-treatment-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <div class="clearfix">
                                <div class="row">
                                    <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">Revenue({{revenue_data.total}})</h4>
                                </div>
                            </div>
                            <canvas id="revenue-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 grid-margin stretch-card" hidden>
                    <div class="card">
                        <div class="card-body">
                            <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div>
                            <div class="clearfix">
                                <div class="row">
                                    <h4 class="card-title float-left col-lg-6 mb-lg-0 mt-1">Transactions()</h4>
                                </div>
                            </div>
                            <canvas id="transactions-chart" class="mt-4 chartjs-render-monitor" width="497" height="248" style="display: block; width: 497px; height: 248px;"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import { mapActions} from "vuex";
import * as Sc from "../../../assets/vendors/chart.js/Chart.min.js"
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"

export default {
    name:'Front',
    title: string.PAGE_TITLE_DASHBOARD,
    components: {
        NavBar,
        SideBar,
        Multiselect,
        Footer,
    },
    data() {
    const now = new Date()
    const current_year = now.getFullYear() - 5

    return {
      opds_data:null,
      opds_renew:null,
      patients_data:null,
      appointments_data:null,
      ipds_data:null,
      ipds_treatment_data:null,
      transactions_data:null,
      revenue_data:null,

      current_year:current_year,
      opds_chart:null,
      opds_renew_chart:null,
      patients_chart:null,
      appointments_chart:null,
      ipds_chart:null,
      ipds_treatment_chart:null,
      transactions_chart:null,
      revenue_chart:null,
      response:{},
    }
    },
    mounted() {
        this.getDashboard();
    },
    methods:{
        ...mapActions("hospital",["getClientDashboardAPI"]),
        getDashboard(){
         var bodyFormData = new FormData();
         bodyFormData.append('client_id', this.$route.params.id)
         this.getClientDashboardAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
              this.response = response
              this.opds_data= response.opds
              this.opds_renew = response.opds_renew
              this.patients_data = response.patients
              this.appointments_data = response.appointments
              this.ipds_data = response.ipds
              this.ipds_treatment_data = response.ipds_treatments
              this.transactions_data = response.transactions
              this.revenue_data = response.last_twelve_month_revenue

              setTimeout(() => {
                if (this.opds_chart != null) {
                    this.opds_chart.destory()
                }
                this.opds_chart = this.setBarChart(
                                    'opds-chart',
                                    this.get_label_for_month(response.opds.last_twelve_month_data),
                                    this.get_month_total(response.opds.last_twelve_month_data), 'opds')


                if (this.opds_renew_chart != null) {
                    this.opds_renew_chart.destory()
                }
                this.opds_renew_chart = this.setBarChart(
                                        'opds-renewal-chart',
                                        this.get_label_for_month(response.opds_renew.last_twelve_month_data),
                                        this.get_month_total(response.opds_renew.last_twelve_month_data), 'renew opds')


                if (this.patients_chart != null) {
                    this.patients_chart.destory()
                }
                this.patients_chart = this.setBarChart(
                                            'patients-chart',
                                            this.get_label_for_month(this.patients_data.last_twelve_month_data),
                                            this.get_month_total(this.patients_data.last_twelve_month_data), 'patients')


                if (this.appointments_chart != null) {
                    this.appointments_chart.destory()
                }
                this.appointments_chart = this.setBarChart(
                                                'appointments-chart',
                                                this.get_label_for_month(this.appointments_data.last_twelve_month_data),
                                                this.get_month_total(this.appointments_data.last_twelve_month_data), 'appointments')


                if (this.ipds_treatment_chart != null) {
                    this.ipds_treatment_chart.destory()
                }
                this.ipds_treatment_chart = this.setBarChart(
                                                'ipds-treatment-chart',
                                                this.get_label_for_month(this.ipds_treatment_data.last_twelve_month_data),
                                                this.get_month_total(this.ipds_treatment_data.last_twelve_month_data), 'treatments')


                if (this.ipds_chart != null) {
                    this.ipds_chart.destory()
                }
                this.ipds_chart = this.setBarChart(
                                                'ipds-chart',
                                                this.get_label_for_month(this.ipds_data.last_twelve_month_data),
                                                this.get_month_total(this.ipds_data.last_twelve_month_data), 'ipds')


                if (this.revenue_chart != null) {
                    this.revenue_chart.destory()
                }
                this.revenue_chart = this.setBarChart(
                                                'revenue-chart',
                                                this.get_label_for_month(this.revenue_data),
                                                this.get_month_total(this.revenue_data), 'revenue')
              }, 2000)

              //this.setDepartmentChartDetails()
              //this.setEarningsChartDetails()
              //this.setDoctorChartDetails()
            }
          });
        },

        get_label_for_month(list) {
          let chart_label = []
          list.forEach((obj) => {
            chart_label.push(obj.month_name)
          })
          return chart_label
        },

        get_month_total(list) {
          let chart_label_total = []
          list.forEach((obj) => {
            chart_label_total.push(obj.total)
          })
          return chart_label_total
        },

        setBarChart(chart_id, chart_label, chart_label_data, legend_label) {
            Chart.defaults.global.legend.labels.usePointStyle = true;
            var ctx = document.getElementById(chart_id).getContext("2d");

            var backgroundColor = [
                'rgba(138, 43, 226, 0.2)',   // Blue Violet
                'rgba(148, 0, 211, 0.2)',    // Dark Violet
                'rgba(186, 85, 211, 0.2)',   // Medium Orchid
                'rgba(218, 112, 214, 0.2)',  // Orchid
                'rgba(128, 0, 128, 0.2)',    // Purple
                'rgba(147, 112, 219, 0.2)',  // Medium Purple
                'rgba(75, 0, 130, 0.2)',     // Indigo
                'rgba(216, 191, 216, 0.2)',  // Thistle
                'rgba(221, 160, 221, 0.2)',  // Plum
                'rgba(238, 130, 238, 0.2)',  // Violet
                'rgba(123, 104, 238, 0.2)',  // Medium Slate Blue
                'rgba(199, 21, 133, 0.2)'    // Medium Violet Red
              ]

              var borderColor = [
                'rgba(138, 43, 226, 0.6)',   // Blue Violet
                'rgba(148, 0, 211, 0.6)',    // Dark Violet
                'rgba(186, 85, 211, 0.6)',   // Medium Orchid
                'rgba(218, 112, 214, 0.6)',  // Orchid
                'rgba(128, 0, 128, 0.6)',    // Purple
                'rgba(147, 112, 219, 0.6)',  // Medium Purple
                'rgba(75, 0, 130, 0.6)',     // Indigo
                'rgba(216, 191, 216, 0.6)',  // Thistle
                'rgba(221, 160, 221, 0.6)',  // Plum
                'rgba(238, 130, 238, 0.6)',  // Violet
                'rgba(123, 104, 238, 0.6)',  // Medium Slate Blue
                'rgba(199, 21, 133, 0.6)'    // Medium Violet Red
              ]
              return new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: chart_label,
                    datasets: [{
                          label: '# of ' + legend_label,
                          data: chart_label_data,
                          backgroundColor: backgroundColor,
                          borderColor:borderColor,
                          borderWidth:1,
                          fill:false,
                          }]
                },
                options: { scales: {
                            yAxes: [{ticks: {beginAtZero: true}}]},
                            legend: {display: false},
                            elements: {point: {radius: 0}}
                          }
              })
        },

        updateFilters() {
            while( this.current_year >= 2023 ) {
              this.statistics_options.push({"display_name":this.current_year,"api_value":this.current_year})
              this.current_year = this.current_year - 1
            }
        }
    }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>