<template>
      <div class="row">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                  <div class="row">
                      <div class="col-lg-4 mb-lg-0 mb-2">
                          <input placeholder="Search"
                                v-model="searchtext"
                                class="form-control" type="search"
                                @input="handleSearchInput"
                                name="searchmedicine" id="searchmedicine" autocomplete="off" v-on:keyup="getClientUsers(1)">
                      </div>
                      <div class="col-lg-6 mb-lg-0 mb-2"></div>
                      <div class="col-lg-2 mb-lg-0 mb-2">
                          <multiselect
                              v-model="filter_status"
                              :options="filter_status_list"
                              :multiple="false"
                              :close-on-select="true"
                              :clear-on-select="false"
                              :preserve-search="true" placeholder="Status"
                              :preselect-first="true"
                              :showLabels="false"
                              :allowEmpty="false"
                              @input=getClientUsers(1)
                          /></multiselect>
                      </div>
                  </div>
                  <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Username</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Role</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(obj,i) in client_users" :key="i">
                              <td>{{i + 1}}</td>
                              <td v-if="obj.is_client_master_user"><span class="logged-in">●</span> {{obj.username}}</td>
                              <td v-else="obj.is_client_master_user">{{obj.username}}</td>
                              <td>{{obj.name}}</td>
                              <td>{{obj.email}}</td>
                              <td>{{obj.mobile}}</td>
                              <td>{{getRole(obj.role)}}</td>
                              <td><label v-bind:class="{'badge badge-success': obj.status === 'Active',
                                    'badge badge-danger': obj.status === 'InActive' || obj.status === 'Inactive'}">{{obj.status}}</label></td>
                              <td>
                                <button type="button" @click="openPasswordModal(obj.id)" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-login"></i></button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <router-link :to="{name:'master-admin-client-user-details',params:{'id':obj.id, 'client_id': obj.client_id}}">
                                    <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button>
                                </router-link> &nbsp;&nbsp;&nbsp;&nbsp;
                                <button type="button" @click="deleteRoomAPI(obj.id, rm.name)" class="btn btn-outline-secondary btn-rounded btn-icon" disabled><i class="mdi mdi-delete"></i></button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                  </div>
                <div v-if="is_API_call_running" class="text-center mt-5">
                  <b-spinner label=""></b-spinner>
                </div>
                <div class="row" v-if="!is_API_call_running && client_users.length == 0" >
                   
                    <div class="col-lg-12 text-center">
                      <br/><br/><br/>
                      <small class="text-muted">{{ errorMessage }}</small>
                      <br/><br/><br/>
                    </div>
                </div>
                <div class="b-pagination-outer" v-if="listPages > 1">
                  <ul id="border-pagination">
                    <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                    <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getClientUsers(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                    <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <b-modal id="modal-prevent-closing" ref="modal" title="" @ok="loginClientUser"  centered>
              <b-form-input v-model="admin_password" placeholder="Enter your password"></b-form-input>
              <br v-if="errormsg != ''"/>
              <p v-if="errormsg != ''" class="text-danger">{{errormsg}}</p>
            </b-modal>
        </div>
</template>
  <script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"
import Swal from 'sweetalert2'
import {set_axios_defaults, set_token,set_user_detail } from '../../../helper/helper';
import Bowser from 'bowser';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export default {
    name:'roomWardListing',
    title: string.PAGE_TITLE_ROOMS_WARDS,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect
    },
    data() {
      return {
          client_users:[],
          is_API_call_running : false,
          listPages:0,
          activePage:1,
          searchtext:'',
          myTimer:null,
          errorMessage : 'No Data Found.',
          filter_status_list:['All', 'Active','Inactive'],
          role_list:[{'id': 1, 'name': 'Admin'},{'id': 2, 'name': 'Doctor'},{'id': 2, 'name': 'Compounder'},],
          filter_status: "All",
          location:'',
          deviceId:'',
          admin_password:'',
          errormsg:'',
          selectedId:'',
      }
    },
    mounted() {
      this.getClientUsers(this.activePage);
      this.getDeviceId();
      this.getLocation();
    },
    methods:{
      ...mapActions("hospital",["getClientUserListAPI", "loginClientUserAPI"]),
  
      getClientUsers(page){
        this.activePage = page;
        clearTimeout(this.myTimer)
        this.myTimer = setTimeout(() => {
          this.is_API_call_running = true;
            this.client_users = [];
            var bodyFormData = new FormData();
            bodyFormData.append('filter_value', this.searchtext);
            bodyFormData.append('filter_status', this.filter_status);
            bodyFormData.append('page', page);
            bodyFormData.append('client_id', this.$route.params.id);
            this.getClientUserListAPI(bodyFormData).then((response) => {
              this.is_API_call_running = false;
              if (response.response_code == 200) {
                this.client_users = response.data;
                this.listPages = response.no_of_pages;
              }else{
                this.errorMessage = response.message;
              }
            });
        }, 500)
      },

      loginClientUser(bvModalEvent){
        bvModalEvent.preventDefault();
        this.errormsg = '';

        const browser = Bowser.getParser(window.navigator.userAgent);
        const result = browser.getBrowser();
        const platform = browser.getPlatform();
        var bodyFormData = new FormData();
        bodyFormData.append('user_id', this.selectedId);
        bodyFormData.append('password', this.admin_password);
        bodyFormData.append('browser_details', platform.type + " " + result.name + result.version);
        bodyFormData.append('system_time_zone', Intl.DateTimeFormat().resolvedOptions().timeZone);
        bodyFormData.append('location', this.location);
        bodyFormData.append('device_id', this.deviceId);

        this.loginClientUserAPI(bodyFormData).then((response) => {
            this.is_API_call_running = false;
            if (response.response_code == 200) {
                set_token(response.data.access_token)
                set_axios_defaults(response.data.access_token)
                set_user_detail(response.data)
                this.$toasted.success(response.message, {duration: 2000,});
                this.$router.push({ name: 'login' });
            } else{
                this.errormsg = response.message;
            }
        });
      },

      previewClick(){
        this.activePage--;
        if(this.activePage >= 1){
          this.getClientUsers(this.activePage);
        }else{
          this.activePage = 1;
        }
      },

      nextClick(){
        this.activePage++;
        if(this.activePage <= this.listPages){
          this.getClientUsers(this.activePage);
        }else{
          this.activePage = this.listPages;
        }
      },

      getRole(role){
        for (let i = 0; i < this.role_list.length; i++) {
            if (parseInt(role) == this.role_list[i].id) {
                return this.role_list[i].name
            }
        }
      },

      handleSearchInput(event){
        if (event.target.value === "") {
          this.getClientUsers(1);
        }
      },
        getDeviceId() {
            // Check if device_id is already stored in cookies
            const storedDeviceId = Cookies.get('device_id');

            if (storedDeviceId) {
              // Use the stored device_id if it exists
              this.deviceId = storedDeviceId;
            } else {
              // Generate a new UUID and store it in cookies
              this.deviceId = uuidv4();
              Cookies.set('device_id', this.deviceId, { expires: 365 }); // Expires in 365 days
            }
          },
        getLocation() {
            return new Promise((resolve, reject) => {
                if (!navigator.geolocation) {
                    reject("Geolocation is not supported by this browser.");
                }

                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.location = position.coords.latitude + ", " + position.coords.longitude
                    },
                    (error) => {
                        reject(error.message);
                    }
                );
            });
        },
        openPasswordModal(id) {
          this.selectedId = id;
          this.$refs.modal.show(); // Show the modal
        },
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
