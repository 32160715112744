<template>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
            <div v-if="is_API_call_running" class="text-center mt-5">
              <b-spinner label=""></b-spinner>
            </div>
            <div class="card-body">
              <h4 class="card-title">Business Details</h4>
              <form class="form-sample">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Business Name</label>
                        <input type="text" class="form-control" v-if="business_name" v-model="$v.typeform_business.business_name.$model" :class="{ 'is-invalid':  $v.typeform_business.business_name.$error  }" placeholder="Business Name"/>
                        <input type="text" class="form-control" v-else v-model="$v.typeform_business.business_name.$model" :class="{ 'is-invalid':  $v.typeform_business.business_name.$error  }" placeholder="Business Name"/>
                          <div v-if="$v.typeform_business.business_name.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.business_name.required">Please enter business name</span>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Owner Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.name.$model" :class="{ 'is-invalid':  $v.typeform_business.name.$error  }" placeholder="Owner Name"/>
                          <div v-if="$v.typeform_business.name.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.name.required">Please enter name</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Email Address</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.email.$model" :class="{ 'is-invalid':  $v.typeform_business.email.$error  }" placeholder="Email Address"d/>
                          <div v-if="$v.typeform_business.email.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.email.required">Please enter email address</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Mobile Number</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.mobile.$model" :class="{ 'is-invalid':  $v.typeform_business.mobile.$error  }" placeholder="Mobile Number"d/>
                          <div v-if="$v.typeform_business.mobile.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.mobile.required">Please enter mobile number</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                      <div class="col-sm-12">
                        <label class="col-form-label">Multi Specialist?</label>
                        <multiselect v-model="$v.typeform_business.multi_specialist.$model" :options="option_yes_no"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Multi Specialist?"
                                     :preselect-first="true" :showLabels="false"></multiselect>
                      <div v-if="$v.typeform_business.multi_specialist.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform_business.multi_specialist.required">Please select Multi Specialist</span>
                      </div>
                      </div>
                  </div>

                  <div class="col-md-3">
                      <div class="col-sm-12">
                        <label class="col-form-label">Are You Doctor?</label>
                        <multiselect v-model="$v.typeform_business.single_doctor.$model" :options="option_yes_no"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Are You Doctor?"
                                     :preselect-first="true" :showLabels="false"></multiselect>
                      <div v-if="$v.typeform_business.single_doctor.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform_business.single_doctor.required">Please select are you doctor</span>
                      </div>
                      </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">No. of Departments(Optional)</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.no_of_department.$model" :class="{ 'is-invalid':  $v.typeform_business.no_of_department.$error  }" placeholder="No. of Department"/>
                          <div v-if="$v.typeform_business.no_of_department.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.no_of_department.required">Please enter department count</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">No. of Staff(Optional)</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.no_of_staff.$model" :class="{ 'is-invalid':  $v.typeform_business.no_of_staff.$error  }" placeholder="No. of Staff"/>
                          <div v-if="$v.typeform_business.no_of_staff.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.no_of_staff.required">Please enter staff count</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Hospital Address</label>
                        <textarea rows="5" class="form-control" v-model="$v.typeform_business.address.$model" :class="{ 'is-invalid':  $v.typeform_business.address.$error  }" placeholder="Hospital Address"/>
                          <div v-if="$v.typeform_business.address.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.address.required">Please enter address</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">City</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.city.$model" :class="{ 'is-invalid':  $v.typeform_business.city.$error  }" placeholder="City"/>
                          <div v-if="$v.typeform_business.city.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.city.required">Please enter city</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">State</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.state.$model" :class="{ 'is-invalid':  $v.typeform_business.state.$error  }" placeholder="State"/>
                          <div v-if="$v.typeform_business.state.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.state.required">Please enter state</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Postal Code</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.postal_code.$model" :class="{ 'is-invalid':  $v.typeform_business.postal_code.$error  }" placeholder="Postal Code"/>
                          <div v-if="$v.typeform_business.postal_code.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.postal_code.required">Please enter postal code</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Country</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.country.$model" :class="{ 'is-invalid':  $v.typeform_business.country.$error  }" placeholder="Country"/>
                          <div v-if="$v.typeform_business.country.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.country.required">Please enter country</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <b-button v-if="is_btn_spinner_business" variant="primary" disabled>
                 <b-spinner small type="grow"></b-spinner>Loading...
              </b-button>
              <div v-else>
                  <button v-if="$route.params.id"type="button" @click="updateClientDetails()" class="btn btn-gradient-primary me-2">Update Details</button>
                  <button v-else type="button" @click="editBusinessDetails()" class="btn btn-gradient-primary me-2">Add Client</button>
              </div>
            </div>

        </div>
      </div>
    <Footer/>
    </div>
</template>
<script>
import NavBar from "@/components/NavBarMaster.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import { mapActions } from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"
import Swal from 'sweetalert2'
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: 'Client Details',
  title: string.PAGE_TITLE_CLIENT_DETAILS,
  components: {
    NavBar,
    SideBar,
    Multiselect
  },
  data() {
    return {
      client_details: null,
      is_API_call_running: false,
      errorMessage: 'No Data Found.',
      option_yes_no :['Yes','No'],
      is_btn_spinner_business: false,
      typeform_business:{
        name:'',
        email:'',
        mobile:'',
        business_name:'',
        multi_specialist:'',
        single_doctor:'',
        no_of_department:'',
        no_of_staff:'',
        address:'',
        city:'',
        state:'',
        postal_code:'',
        country:'',
      },
    }
  },
    validations: {
        typeform_business:{
          name:{required},
          email:{required},
          mobile:{required,numeric},
          business_name:{required},
          multi_specialist:{required},
          single_doctor:{required},
          no_of_department:{required},
          no_of_staff:{required},
          address:{required},
          city:{required},
          state:{required},
          postal_code:{required},
          country:{required},
        },
  },
  mounted() {
    this.getClientDetails();
  },
  methods: {
    ...mapActions("hospital", ["getClientListAPI", "updateClientDetailsAPI"]),
    getClientDetails(){
      this.is_API_call_running = true;
      var bodyFormData = new FormData();;
      bodyFormData.append('client_id', this.$route.params.id);

      this.getClientListAPI(bodyFormData).then((response) => {
        this.is_API_call_running = false
        if (response.response_code == 200) {
            this.client_details = response.data;
            this.typeform_business = {
              name: response.data.name,
              email: response.data.email,
              mobile: response.data.mobile,
              business_name: response.data.business_name,
              multi_specialist: response.data.multi_specialist,
              single_doctor: response.data.single_doctor,
              no_of_department: response.data.no_of_department,
              no_of_staff: response.data.no_of_staff,
              address: response.data.address,
              city: response.data.city,
              state: response.data.state,
              postal_code: response.data.postal_code,
              country: response.data.country,
            }
        } else{
            this.errorMessage = response.message;
        }
      });
    },

    updateClientDetails() {
      this.$v.$touch();
      if (!this.$v.typeform_business.$invalid) {
        this.is_btn_spinner_business=true;
        var bodyFormData = new FormData();
        bodyFormData.append('client_id', this.$route.params.id);
        bodyFormData.append('name', this.typeform_business.name);
        bodyFormData.append('email', this.typeform_business.email);
        bodyFormData.append('mobile', this.typeform_business.mobile);
        bodyFormData.append('business_name', this.typeform_business.business_name);
        bodyFormData.append('multi_specialist', this.typeform_business.multi_specialist);
        bodyFormData.append('single_doctor', this.typeform_business.single_doctor);
        bodyFormData.append('no_of_department', this.typeform_business.no_of_department);
        bodyFormData.append('no_of_staff', this.typeform_business.no_of_staff);
        bodyFormData.append('address', this.typeform_business.address);
        bodyFormData.append('city', this.typeform_business.city);
        bodyFormData.append('state', this.typeform_business.state);
        bodyFormData.append('postal_code', this.typeform_business.postal_code);
        bodyFormData.append('country', this.typeform_business.country);

        this.updateClientDetailsAPI(bodyFormData).then((response) => {
          this.is_btn_spinner_business=false;
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
            this.$router.push({ name: 'master-admin-clients' });
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
      }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>