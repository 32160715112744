<template>
      <div class="row">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                  <div class="row">
                      <div class="col-lg-4 mb-lg-0 mb-2">
                          <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchmedicine" id="searchmedicine" autocomplete="off" v-on:keyup="getClientTransaction(1)">
                      </div>
                      <div class="col-lg-4 mb-lg-0 mb-2"></div>
                      <div class="col-lg-2 mb-lg-0 mb-2">
                        <date-range-picker
                                    class="col-lg-12 mb-lg-0 mb-2"
                                    ref="picker"
                                    :max-date="MAXDATE"
                                    :opens="center"
                                    :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                    :single-date-picker="false"
                                    :showDropdowns="showDropdowns"
                                    :show-week-numbers= "false"
                                    :time-picker="false"
                                    :autoApply="true"
                                    v-model="dateRange"
                                    :linked-calendars="true"
                                    :dateFormat="dd-mmm-yyyy"
                                    @finish-selection=getClientTransaction(1)
                                    @update=getClientTransaction(1)
                            />
                      </div>
                      <div class="col-lg-2 mb-lg-0 mb-2">
                            <multiselect
                                v-model="filter_status"
                                label = "name"
                                :options="filter_status_list"
                                :multiple="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :preserve-search="true" placeholder=""
                                :preselect-first="true"
                                :showLabels="false"
                                :allowEmpty="false"
                                @input=getClientTransaction(1)
                            /></multiselect>
                      </div>
                  </div>
                <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Order Id</th>
                          <th>Subscription Start Date</th>
                          <th>Expire</th>
                          <th>Payment Id</th>
                          <th>Amount</th>
                          <th>Payment Status</th>
                          <th>Receipt</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(transaction,i) in client_transactions" :key="i">
                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-if="(transaction.payment_status == 'Completed' || transaction.payment_status == 'Refunded') && transaction.subscription_plan && !transaction.subscription_plan.is_subscription_expired"><span class="logged-in">●</span> {{transaction.order_id}}</td>
                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-else>{{transaction.order_id}}</td>

                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-if="transaction.subscription_plan">{{transaction.subscription_plan.purchased_date}}</td>
                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-else>-</td>

                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)"  v-if="transaction.subscription_plan">{{transaction.subscription_plan.plan_expire_in}}</td>
                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-else>-</td>

                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{transaction.payment_id}}</td>
                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >$ {{transaction.amount}}</td>
                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)">
                              <label v-bind:class="{
                                    'badge badge-success': transaction.payment_status_display === 'Completed',
                                    'badge badge-warning': transaction.payment_status_display === 'Pending' || transaction.payment_status_display === 'Processing',
                                    'badge badge-info': transaction.payment_status_display === 'Refunded',
                                    'badge badge-danger': transaction.payment_status_display === 'Hold' || transaction.payment_status_display === 'Failed',
                                    }">
                              {{transaction.payment_status_display}}
                              </label>
                              </td>

                              <td v-if="transaction.payment_status_display == 'Completed' || transaction.payment_status_display == 'Refunded'"><a href="" @click="downloadPaymentTransactionInvoice($event, transaction.order_id)">Download - {{transaction.receipt_id}}</a></td>
                              <td v-else><a href="" @click="verifyTransactionPayment($event, transaction.order_id)">Verify Payment</a></td>

                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{moment(transaction.created_at).format('DD MMM YYYY, HH:mm')}}</td>
                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{moment(transaction.updated_at).format('DD MMM YYYY, HH:mm')}}</td>
                              <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{transaction.notes}}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div v-if="is_API_call_running" class="text-center mt-5">
                  <b-spinner label=""></b-spinner>
                </div>
                <div class="row" v-if="!is_API_call_running && client_transactions.length == 0" >
                   
                    <div class="col-lg-12 text-center">
                      <br/><br/><br/>
                      <small class="text-muted">{{ errorMessage }}</small>
                      <br/><br/><br/>
                    </div>
                </div>
                <div class="b-pagination-outer" v-if="listPages > 1">
                  <ul id="border-pagination">
                    <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                    <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getClientTransaction(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                    <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                  </ul>
                </div>
              </div>
            </div>
    <SubscriptionDetailsModel ref="subscriptionDetailsComponent"/>
        </div>
</template>
  <script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"
import Swal from 'sweetalert2'
import DateRangePicker from 'vue2-daterange-picker'
import SubscriptionDetailsModel from "../../model/subscription_plan_details"

export default {
    name:'roomWardListing',
    title: string.PAGE_TITLE_ROOMS_WARDS,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      DateRangePicker,
      SubscriptionDetailsModel,
    },
    data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  	const maxDate = new Date(today)
      return {
          moment,
          client_transactions:[],
          is_API_call_running : false,
          listPages:0,
          activePage:1,
          searchtext:'',
          myTimer:null,
          errorMessage : 'No Data Found.',
          room_type_list:['All','ICU', 'MICU', 'SICU', 'WARD', 'Special Room', 'Twin Sharing Room', 'General Room', 'Recovery Room'],
          filter_status_list:[
                            {'id':'All','name':'All'},
                            {'id':'0','name':'Pending'},
                            {'id':'1','name':'Processing'},
                            {'id':'2','name':'Completed'},
                            {'id':'3','name':'Hold'},
                            {'id':'4','name':'Failed'},
                            {'id':'5','name':'Refunded'},
                        ],
          ward_list:[],
          room_type: "All",
          filter_status: {'id':'All','name':'All'},
          ward: {name:"All", id:0},
        dateRange:{
            startDate: today,
            endDate: today
        },
        MAXDATE:maxDate,
      }
    },
    mounted() {
      this.getClientTransaction(this.activePage);
    },
    methods:{
      ...mapActions("hospital",["getClientTransactionAPI", "getIPDWardListData", "deleteRoom"]),
  
      getClientTransaction(page){
        this.activePage = page;
        clearTimeout(this.myTimer)
        this.myTimer = setTimeout(() => {
          this.is_API_call_running = true;
            this.client_transactions = [];
            var bodyFormData = new FormData();
            bodyFormData.append('page', page);
            bodyFormData.append('filter_value', this.searchtext);
            bodyFormData.append('filter_from_date', moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'));
            bodyFormData.append('filter_to_date', moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'));
            bodyFormData.append('filter_status', this.filter_status.id);
            bodyFormData.append('client_id', this.$route.params.id);
            this.getClientTransactionAPI(bodyFormData).then((response) => {
              this.is_API_call_running = false;
              if (response.response_code == 200) {
                this.client_transactions = response.data;
                this.listPages = response.no_of_pages;
              }else{
                this.errorMessage = response.message;
              }
            });
        }, 500)
      },

          previewClick(){
            this.activePage--;
            if(this.activePage >= 1){
              this.getClientTransaction(this.activePage);
            }else{
              this.activePage = 1;
            }
          },

          nextClick(){
            this.activePage++;
            if(this.activePage <= this.listPages){
              this.getClientTransaction(this.activePage);
            }else{
              this.activePage = this.listPages;
            }
          },
        getWardList() {
          this.ward_list = [];
          var bodyFormData = new FormData();
          bodyFormData.append('search_text', '');
          bodyFormData.append('page', 1);
          this.getIPDWardListData(bodyFormData).then((response) => {
              if (response.response_code == 200) {
                this.ward_list.push({"id":0, "name":"All"})
                response.data.forEach((obj) => {
                    this.ward_list.push(obj);
                })
              } else {
                this.errorMessage = response.message;
              }
          });
        },
        openSubscriptionPlanModel(subscription_plan) {
            if(subscription_plan == null || subscription_plan == undefined){
                return
            } else {
                this.$refs.subscriptionDetailsComponent.clearData();
                this.$refs.subscriptionDetailsComponent.receiveData(subscription_plan);
                this.$refs.subscriptionDetailsComponent.show();
            }
        },
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
