<template>
      <div class="row">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
               <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Plan Name</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Paid Price</th>
                      <th>Price</th>
                      <th>Discount Price</th>
                      <th></th>
                    </tr>
                  </thead>                  
                  <tbody>                  
                    <tr v-for="(obj,i) in client_subscriptions" :key="i">
                      <td>{{i + 1}}</td>
                      <td>{{obj.subscription_plan.plan_name}}</td>
                      <td>{{moment(obj.subscription_date).format('DD MMM YYYY, HH:mm:ss')}}</td>
                      <td>{{moment(obj.subscription_expire_date).format('DD MMM YYYY, HH:mm:ss')}}</td>
                      <td>$ {{obj.subscription_paid_price}}</td>
                      <td>$ {{obj.subscription_price}}</td>
                      <td>$ {{obj.subscription_discount}}</td>
                      <td>
                        <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon" @click="openSubscriptionModel(obj, i)"><i class="mdi mdi-eye"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
               </div>
                <div v-if="is_API_call_running" class="text-center mt-5">
                  <b-spinner label=""></b-spinner>
                </div>
                <div class="row" v-if="!is_API_call_running && client_subscriptions.length == 0" >
                   
                    <div class="col-lg-12 text-center">
                      <br/><br/><br/>
                      <small class="text-muted">{{ errorMessage }}</small>
                      <br/><br/><br/>
                    </div>
                </div>
                <div class="b-pagination-outer" v-if="listPages > 1">
                  <ul id="border-pagination">
                    <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                    <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getClientSubscription(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                    <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <SubscriptionDetailsModel ref="subscriptionDetailsComponent"/>
        </div>
</template>
  <script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions} from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"
import Swal from 'sweetalert2'
import SubscriptionDetailsModel from "../models/edit_subscription_details"

export default {
    name:'Client Subscriptions',
    title: string.PAGE_TITLE_CLIENT_SUBSCRIPTIONS,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      SubscriptionDetailsModel,
    },
    data() {
      return {
          moment,
          client_subscriptions:[],
          is_API_call_running : false,
          listPages:0,
          activePage:1,
          errorMessage : 'No Data Found.',
          is_listing_model : false,
          edit_index : -1,
      }
    },
    mounted() {
      this.getClientSubscription(this.activePage);
    },
    methods:{
      ...mapActions("hospital",["getClientSubscriptionAPI"]),
  
      getClientSubscription(page){
        this.is_API_call_running = true;
        this.client_subscriptions = [];
        var bodyFormData = new FormData();
        bodyFormData.append('page', page);
        bodyFormData.append('client_id', this.$route.params.id);
        this.getClientSubscriptionAPI(bodyFormData).then((response) => {
          this.is_API_call_running = false;
          if (response.response_code == 200) {
            this.client_subscriptions = response.data;
            this.listPages = response.no_of_pages;
          }else{
            this.errorMessage = response.message;
          }
        });
      },

      previewClick(){
        this.activePage--;
        if(this.activePage >= 1){
          this.getClientSubscription(this.activePage);
        }else{
          this.activePage = 1;
        }
      },

      nextClick(){
        this.activePage++;
        if(this.activePage <= this.listPages){
          this.getClientSubscription(this.activePage);
        }else{
          this.activePage = this.listPages;
        }
      },
        openSubscriptionModel(subscription, index) {
            if(subscription == null || subscription == undefined){
                return
            } else {
                this.$refs.subscriptionDetailsComponent.clearData();
                this.$refs.subscriptionDetailsComponent.receiveData(subscription);
                this.$refs.subscriptionDetailsComponent.show();
                this.edit_index = index
                if(this.is_listing_model == false){
                    this.is_listing_model = true
                    var myParent = this
                    this.$refs.subscriptionDetailsComponent.$on("subscription_details", function (subscription_details) {
                        if(myParent.edit_index >= 0) {
                            myParent.client_subscriptions.splice(myParent.edit_index, 1, subscription_details);
                        } else {
                            myParent.getClientSubscription(myParent.activePage);
                        }
                    });
                }
            }
        },
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>