<template>
  <b-modal ref="modal2" id="modal2" title="Subscription Plan Details" centered size="huge" hide-footer>
    <form class="form-sample">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Select Feature</label>
                <multiselect v-model="$v.typeform.subscription_plan.$model" :options="subscription_plans"
                             label = "plan_name"
                             :multiple="false" :close-on-select="true" :clear-on-select="false"
                             :preserve-search="true" placeholder="Select Feature"
                             :preselect-first="false" :showLabels="false"
                             @input=updatePlanPrice() disabled
                             ></multiselect>
                <div v-if="$v.typeform.subscription_plan.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.subscription_plan.required">Please select plan</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Plan Price(USD)</label>
                <input type="text" class="form-control" v-model="$v.typeform.plan_price.$model"
                    :class="{ 'is-invalid':  $v.typeform.plan_price.$error  }"
                    placeholder="Plan Price(USD)" disabled/>
                <div v-if="$v.typeform.plan_price.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.plan_price.required">Please enter plan price</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Discount(USD)</label>
                <input type="text" class="form-control" v-model="$v.typeform.discount.$model"
                    :class="{ 'is-invalid':  $v.typeform.discount.$error  }"
                    placeholder="Discount(USD)"/>
                <div v-if="$v.typeform.discount.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.discount.required">Please enter discount</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Paid Price<br/>(USD)</label>
                <input type="text" class="form-control" v-model="$v.typeform.paid_price.$model"
                    :class="{ 'is-invalid':  $v.typeform.paid_price.$error  }"
                    placeholder="Paid Price"/>
                <div v-if="$v.typeform.paid_price.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.paid_price.required">Please enter paid price</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Subscription Start Date(yyyy-MM-dd HH:mm:ss)</label>
                <input type="text" class="form-control" v-model="$v.typeform.subscription_date.$model"
                    :class="{ 'is-invalid':  $v.typeform.subscription_date.$error  }"
                    placeholder="Subscription Start Date"/>
                <div v-if="$v.typeform.subscription_date.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.subscription_date.required">Please enter start date</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group row">
              <div class="col-sm-12">
                <label class="col-form-label">Subscription End Date(yyyy-MM-dd HH:mm:ss)</label>
                <input type="text" class="form-control" v-model="$v.typeform.subscription_end_date.$model"
                    :class="{ 'is-invalid':  $v.typeform.subscription_end_date.$error  }"
                    placeholder="Subscription End Date"/>
                <div v-if="$v.typeform.subscription_end_date.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.subscription_end_date.required">Please enter end date</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <h4>Features</h4>
        <br/>
        <div class="row">
            <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Feature Name</th>
                      <th>Feature Desc</th>
                      <th>Max Limit</th>
                      <th>Max Limit Period</th>
                      <th>status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(obj,i) in feature_list" :key="i">
                        <td>{{i + 1}}</td>
                        <td>{{obj.feature_name}}</td>
                        <td>{{obj.feature_desc}}</td>
                        <td>{{obj.max_limit}}</td>
                        <td>{{obj.max_limit_period}}</td>
                        <td><label v-bind:class="{'badge badge-success': obj.status === 'Active',
                           'badge badge-danger': obj.status === 'InActive' ||
                           obj.status === 'Inactive'}"</label></td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
          <button type="button"
            class="btn btn-gradient-primary"
            v-if="subscription_details.id"
            @click="updateSubscriptionPlan()"
            >Update Client Subscription</button>
    </form>
  </b-modal>
</template>
<script>
import { mapActions} from "vuex";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';

export default {
    components: {
      Multiselect,
    },
    data() {
        return {
          moment,
          subscription_details: null,
          feature_list: [ ],
          typeform:{
            subscription_plan: null,
            subscription_date: '',
            subscription_end_date: '',
            discount: '',
            paid_price: '',
            plan_price: '',
          },
          subscription_plans: [],
          filter_status: 'All',
          filter_auto_renewal: {"id":"All", "name":"All"},
        }
    },
    validations: {
        typeform: {
            subscription_plan: {required},
            subscription_date: {required},
            discount: {required},
            paid_price: {required},
            plan_price: {required},
            subscription_end_date: {required},
        }
    },
    mounted(){
        this.getSubscriptionsPlans()
    },
    methods: {
        ...mapActions("hospital",["updateClientSubscriptionAPI", "getSubscriptionPlanListAPI"]),
        show() {
            this.$refs.modal2.show();
            if(this.subscription_details) {
                this.typeform.id = this.subscription_details.id
                this.typeform.subscription_plan = this.subscription_details.subscription_plan
                this.feature_list = this.subscription_details.subscription_plan.features
                this.typeform.plan_price = this.subscription_details.subscription_plan.plan_price
                this.typeform.subscription_date = this.subscription_details.subscription_date
                this.typeform.subscription_end_date = this.subscription_details.subscription_expire_date
                this.typeform.discount = this.subscription_details.subscription_discount
                this.typeform.paid_price = this.subscription_details.subscription_paid_price
            }
        },

        hide() {
            this.$refs.modal2.hide();
        },

        clearData() {
            this.typeform.subscription_plan = null
            this.typeform.subscription_date = ''
            this.typeform.subscription_end_date = ''
            this.typeform.plan_price = ''
            this.typeform.discount = ''
            this.typeform.paid_price = ''
            this.typeform.status = ''
            this.subscription_details = null
        },

        receiveData(subscription_details) {
            this.subscription_details = subscription_details
        },

        addFeature() {
            this.$v.$touch();
            if (!this.$v.typeform.$invalid) {
                this.subscription_details = {
                    "subscription_plan": this.typeform.subscription_plan.id,
                    "feature_desc": this.typeform.feature_desc,
                    "status": this.typeform.status,
                }
                this.$emit('subscription_details', this.subscription_details)
                this.hide()
            }
        },

        updateSubscriptionPlan() {
          var bodyFormData = new FormData();
          bodyFormData.append('subscription_id', this.subscription_details.id);
          bodyFormData.append('subscription_date', this.typeform.subscription_date);
          bodyFormData.append('subscription_expire_date', this.typeform.subscription_end_date);
          bodyFormData.append('subscription_discount', this.typeform.discount);
          bodyFormData.append('subscription_price', this.typeform.plan_price);
          bodyFormData.append('subscription_paid_price', this.typeform.paid_price);
          this.updateClientSubscriptionAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.$toasted.success(response.message, {duration: 2000,});
                this.$emit('subscription_details', response.data)
                this.hide()
            }else{
                this.$toasted.error(response.message, {duration: 2000,});
            }
          });
        },

        getSubscriptionsPlans() {
          this.subscription_plans = [];
          var bodyFormData = new FormData();
          bodyFormData.append('filter_value', '');
          bodyFormData.append('filter_status', this.filter_status);
          bodyFormData.append('filter_auto_renewal', this.filter_auto_renewal.id);
          this.getSubscriptionPlanListAPI(bodyFormData).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.subscription_plans = response.data;
            } else {
                this.$toasted.error(response.message, {duration: 2000,});
            }
          });
        },
        updatePlanPrice() {
            this.typeform.plan_price = this.typeform.subscription_plan.plan_price
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

