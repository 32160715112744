<template>
  <div>
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="page-header">
            <h4 class="card-title col-lg-10">Client Details</h4>
            <!-- <button v-if="tabIndex == 0" class="col-lg-2 btn btn-gradient-primary" @click=openNextScreen()>Add Room</button>
            <button v-if="tabIndex == 1" class="col-lg-2 btn btn-gradient-primary"@click=openNextScreen()>Add Ward</button>
            -->
          </div>
          <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
              <div class="card">
                  <div class="row">
                    <div>
                      <b-tabs content-class="mt-1" small card v-model="tabIndex">
                        <b-tab title="Dashboard" :title-link-class="linkClass(0)" active>
                          <ClientDashboard />
                        </b-tab>
                        <b-tab title="Details" :title-link-class="linkClass(1)">
                          <ClientDetailsPage />
                        </b-tab>
                        <b-tab title="Users" :title-link-class="linkClass(2)">
                          <ClientUserListing />
                        </b-tab>
                        <b-tab title="Subscriptions" :title-link-class="linkClass(3)">
                          <ClientSubscription />
                        </b-tab>
                        <b-tab title="Transactions" :title-link-class="linkClass(4)">
                          <ClientTransactions />
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBarMaster.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import ClientDetailsPage from "./ClientDetailsPage.vue";
import ClientUserListing from "./UserListing.vue"
import ClientSubscription from "./ClientSubscriptions.vue"
import ClientTransactions from "./ClientTransactionListing.vue"
import ClientDashboard from "./ClientDashboard.vue"
import { mapActions } from "vuex";
import Multiselect from 'vue-multiselect';
import string from "../../../constant/strings.js"

export default {
  name: 'client-details',
  title: string.PAGE_TITLE_CLIENT_DETAILS,
  components: {
    NavBar,
    Footer,
    SideBar,
    Multiselect,
    ClientDetailsPage,
    ClientUserListing,
    ClientSubscription,
    ClientTransactions,
    ClientDashboard,
  },
  data() {
    return {
        tabIndex: 0
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions("hospital", []),
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['btn-gradient-primary m-1', 'text-light']
            } else {
                return ['btn-gradient-secondary', 'text-black']
            }
        },

        openNextScreen() {
            if(this.tabIndex == 0) {
              this.$router.push({ name: 'addroom' });
            } else if(this.tabIndex == 1) {
              this.$router.push({ name: 'addroom' });
            } else if(this.tabIndex == 2) {
              this.$router.push({ name: 'addroom' });
            } else {
              this.$router.push({ name: 'addward' });
            }
        }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>